"use client";

import { usePathname } from "next/navigation";
import React, { Suspense } from "react";

import styles from "./layout.module.scss";
import Components from "@/components";
import { useOpen } from "../layout";

const HomeLayout = ({ children }) => {
  const pathname = usePathname();

  const { open } = useOpen();
  return (
    <>
      <aside>
        <Components.LeftSidebar
          onOpen={open}
          className={
            pathname === "/"
              ? styles["landing-sidebar"]
              : styles["general-sidebar"]
          }
        />
      </aside>
      <section className={styles.content}>
        <Suspense fallback={<Components.PageLoader />}>{children}</Suspense>
      </section>
    </>
  );
};

export default HomeLayout;
